import React from 'react'
import Layout from '../../components/layout'
import SEO from "../../components/seo"
import Contact from "../../components/sections/Contact"
import Services from '../../components/sections/Services'

const NodeJsDeveloper = () => {
  return (
    <>
    <SEO title="vacancies"/>
    <Layout>
        <article className='co-page'>
                <section className="typical__padding co-bullet">
                    <div className="section-container">
                        <h2 className="section-title">Node.js Developer</h2>
                        <p className="co-bullet-info">
                            We are a team of payment technologies seeking passionate professionals to reach new heights. DigiMeth is currently in search of a <b>Node.js.</b> developer.
                        </p>
                        <div className="co-vacancies--grid">
                            <h3 className='co-bullet-title'>About DigiMeth</h3>
                            <p className="co-bullet-info">
                                Digital Method is a software development company specialising in payment technology. Our mission is to provide fast, high-quality and diverse online payment experience to our partners and their customers. We provide international and local payments for all business types.
                            </p>
                            <h3 className='co-bullet-title'>What you`ll be doing</h3>
                            <ul className='co-bullet-list'>
                                <li>Developing In-house platforms.</li>
                                <li>Integrating 3rd party API.</li>
                                <li>Participating in developing new features from scratch.</li>
                            </ul>

                            <h3 className='co-bullet-title'>What you`ll need</h3>
                            <ul className='co-bullet-list'>
                                <li>Strong knowledge of JavaScript and the ECMAScript 6 standards.</li>
                                <li>3+ years of commercial experience with Node.js.</li>
                                <li>Commercial experience working with SQL.</li>
                                <li>Understanding of microservice architecture principles.</li>
                                <li>Experience with REST API;</li>
                                <li>Experience with Queue (RabbitMQ, Kafka, Nats etc.)</li>
                                <li>Experience with Redis</li>
                                <li>Experience with one of Express.js/Koa</li>
                            </ul>

                            <h3 className='co-bullet-title'>Nice to have</h3>
                            <ul className='co-bullet-list'>
                                <li>2+ years of hands-on experience developing CI/CD/Micro Services architecture/Containers</li>
                                <li>Experience with PM2/docker-compose</li>
                                <li>Experience with Amazon (SQS,S3)</li>
                                <li>Experience with TypeScript</li>
                                <li>Experience with GraphQL</li>
                                <li>Experience with sequelize/typeorm</li>
                                <li>Experience with databases PostgreSQL</li>
                            </ul>

                            <p className="co-bullet-info">Work schedule - 5/2, 09:00-18:00 (aligned with banking system operations).</p>

                            <div className='co-join-container'>
                                <span>If you are interested in working with us, send your CV here</span>{' '}
                                <a href="mailto:contact@digimeth.net">contact@digimeth.net</a>
                            </div>

                        </div>
                    </div>
                </section>
                <Services title="Work at Digimeth Feels Perfect!" desc="Our goal is to create fantastic payment solutions:" />
                <Contact/>
            </article>
        </Layout>
    </>
  )
}

export default NodeJsDeveloper